// CalendarPage.duck.js

import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Helper function to sort transactions by booking start date
const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      const booking = tx.booking;
      return booking ? booking.attributes.start : null;
    })
  );

// ================ Action types ================ //

export const FETCH_CALENDAR_REQUEST = 'app/CalendarPage/FETCH_CALENDAR_REQUEST';
export const FETCH_CALENDAR_SUCCESS = 'app/CalendarPage/FETCH_CALENDAR_SUCCESS';
export const FETCH_CALENDAR_ERROR = 'app/CalendarPage/FETCH_CALENDAR_ERROR';

export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_PENDING = 'transition/confirm-payment';
export const TRANSITION_COMPLETED = 'transition/complete';


// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchCalendarError: null,
  transactionRefs: [],
};

export default function calendarPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CALENDAR_REQUEST:
      return { ...state, fetchInProgress: true, fetchCalendarError: null };
    case FETCH_CALENDAR_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
      };
    }
    case FETCH_CALENDAR_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchCalendarError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchCalendarRequest = () => ({ type: FETCH_CALENDAR_REQUEST });
const fetchCalendarSuccess = response => ({
  type: FETCH_CALENDAR_SUCCESS,
  payload: response,
});
const fetchCalendarError = e => ({
  type: FETCH_CALENDAR_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search) => (dispatch, getState, sdk) => {
    const { tab } = params;
  
    const onlyFilterValues = {
      bookings: 'order',
      listings: 'sale',
    };
  
    const onlyFilter = onlyFilterValues[tab];
    if (!onlyFilter) {
      return Promise.reject(new Error(`Invalid tab for CalendarPage: ${tab}`));
    }
  
    dispatch(fetchCalendarRequest());
  
  
    const apiQueryParams = {
      only: onlyFilter,  
      lastTransitions: [TRANSITION_ACCEPT, TRANSITION_PENDING], // Include both accepted and pending
      include: ['listing', 'provider', 'customer', 'booking'],
      'fields.transaction': [
        'processName',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'lineItems',
      ],
      'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    };
  
    return sdk.transactions
      .query(apiQueryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchCalendarSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(fetchCalendarError(storableError(e)));
        throw e;
      });
  };